import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import CommercialTemplate from '../templates/CommercialTemplate';
import Helmet from 'react-helmet';

const HomePage = ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<Layout>
			<Helmet>
				<title>Aeroseal Barrie | Commercial</title>
			</Helmet>
			<CommercialTemplate content={content} />
		</Layout>
	);
};

export default HomePage;

export const query = graphql`
	{
		allMarkdownRemark(filter: {frontmatter: {slug: {eq: "commercial"}}}) {
			edges {
				node {
					frontmatter {
						masthead_heading
						secondary_heading
						details_heading
						detail_1_heading
						detail_1_content
						detail_2_heading
						detail_2_content
						detail_3_heading
						detail_3_content
						detail_4_heading
						detail_4_content
						detail_5_heading
						detail_5_content
						step_1
						step_2
						step_3
						testimonials_heading
						testimony_1_content
						testimony_2_content
						contact_heading
						contact_content
					}
				}
			}
		}
	}
`;